'use client';

import { Box, Container, Grid, Stack } from '@mui/material';
import { Paragraph } from '@web/components/Typography';
import NextImage from 'next/image';
import DumbLink from '@web/components/DumbLink';

const Footer = () => {
  return (
    <footer>
      <Box bgcolor="#222935">
        <Container
          sx={{
            p: '1rem',
            color: 'white',
          }}
        >
          <Box py={4} overflow="hidden">
            <Grid container spacing={3}>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <DumbLink href="/">
                  <NextImage
                    src="/assets/logo.webp"
                    alt="Buy wisely"
                    width={40}
                    height={40}
                  />
                </DumbLink>

                <Paragraph mb={2.5} color="grey.500">
                  BuyWisely is your one stop price comparison platform,
                  delivering the best deals from over 20,000 online shops. We
                  empower shoppers to make smart, cost-effective choices by
                  offering transparent pricing, price history, and the latest
                  deals across a broad range of products. With BuyWisely, your
                  money goes further.
                </Paragraph>
              </Grid>

              <Grid item lg={2} md={6} sm={6} xs={12}>
                <Box
                  fontSize="18px"
                  fontWeight="600"
                  mb={1.5}
                  lineHeight="1"
                  color="white"
                >
                  Popular Shops
                </Box>

                {shops.map((item, ind) => (
                  <DumbLink href={`/shop/${item}`} key={ind} passHref>
                    <Box
                      sx={{
                        display: 'block',
                        borderRadius: 4,
                        cursor: 'pointer',
                        position: 'relative',
                        padding: '0.3rem 0rem',
                        color: '#AEB4BE',
                        '&:hover': {
                          color: '#F6F9FC',
                        },
                      }}
                    >
                      {item}
                    </Box>
                  </DumbLink>
                ))}
              </Grid>

              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Box
                  fontSize="18px"
                  fontWeight="600"
                  mb={1.5}
                  lineHeight="1"
                  color="white"
                >
                  Popular Categories
                </Box>

                <div>
                  {categories.map((item, ind) => (
                    <DumbLink href={`/category/${item}`} key={ind} passHref>
                      <Box
                        sx={{
                          display: 'block',
                          borderRadius: 4,
                          cursor: 'pointer',
                          position: 'relative',
                          padding: '0.3rem 0rem',
                          color: '#AEB4BE',
                          '&:hover': {
                            color: '#F6F9FC',
                          },
                        }}
                      >
                        {item}
                      </Box>
                    </DumbLink>
                  ))}
                </div>
              </Grid>

              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Box
                  fontSize="18px"
                  fontWeight="600"
                  mb={1.5}
                  lineHeight="1"
                  color="white"
                >
                  Contact Us
                </Box>

                <Box py={0.6} color="grey.500">
                  <DumbLink href="mailto:andrew@buywisely.com.au">
                    andrew@buywisely.com.au
                  </DumbLink>
                </Box>
                <Box
                  fontSize="18px"
                  fontWeight="600"
                  my={1.5}
                  lineHeight="1"
                  color="white"
                >
                  <DumbLink href="/disclosure">Affiliate Disclosure</DumbLink>
                </Box>
                <DumbLink
                  href={
                    process.env.COUNTRY_CODE === 'us'
                      ? 'https://buywisely.com.au'
                      : 'https://buywisely.io'
                  }
                  target="_blank"
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    alignContent="center"
                    spacing={1}
                  >
                    <Box>
                      Go to
                      <strong>
                        {process.env.COUNTRY_CODE === 'us'
                          ? ' BuyWisely Australia'
                          : ' BuyWisely US'}
                      </strong>
                    </Box>
                    <NextImage
                      src={
                        process.env.COUNTRY_CODE === 'us'
                          ? 'https://flagcdn.com/w40/au.png'
                          : 'https://flagcdn.com/w40/us.png'
                      }
                      alt={
                        process.env.COUNTRY_CODE === 'us'
                          ? 'Australian flag'
                          : 'US flag'
                      }
                      width={24}
                      height={16}
                    />
                  </Stack>
                </DumbLink>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </footer>
  );
};
const shops =
  process.env.COUNTRY_CODE === 'us'
    ? []
    : [
        'Amazon.com.au',
        'Temu',
        'eBay.com.au',
        'Myer',
        'JB Hi-Fi',
        'Catch.com.au',
        'Kogan.com',
        'Harvey Norman Australia',
        'MyDeal',
        'Bing Lee Electrics',
        'The Good Guys',
        'Bunnings Warehouse',
        'Officeworks',
        'Woolworths',
        'BIG W',
      ];
const categories = [
  'Electronics',
  'Home Appliances',
  'Fashion',
  'Cookware',
  'Gaming Monitors',
  'Games',
  'Baby & Kids',
  'Pets',
  'Grocery',
  'Kitchen',
  'Skirts',
];
export default Footer;
